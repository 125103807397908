import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Contact from "../components/contact";
// import Contact2 from "../components/contact";

export default function(props) {
    return (
        <Layout>
            <SEO 
            title="İletişim Bilgilerimiz" 
            />
            <div style={{ minHeight: "600px" }}>
                <Contact />
            </div>
            
        </Layout>
    );
}
