import React from "react";
import { useStaticQuery, graphql } from "gatsby";

function MapItem(props) {
    const data = props.data;
    return (
        <li>
            <a href={data.url} title={data.name}>
                {data.name}
            </a>
        </li>
    );
}

export default function() {
    const data = useStaticQuery(graphql`
        query MapQuery {
            site {
                siteMetadata {
                    contact {
                        maps {
                            name
                            url
                        }
                    }
                }
            }
        }
    `);
    const items = data.site.siteMetadata.contact.maps;
    let list = [];
    items.forEach(function(e, i) {
        list.push(<MapItem key={e.url + "-" + i} data={e} />);
    });
    return <ul className="map-links">{list}</ul>;
}
