import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Script from "react-load-script";
import { PaperPlane, Mapmarker, Mobile, Envelope, Loading } from "./icons";
import SocialLinks from "./sociallinks";
import MapLinks from "./maplinks";
import "../style/contact.less";

class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: false,
            submitDisabled: false
        };

        this.textAreaInput = this.textAreaInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.showContactForm = false;

        if (this.props.contact.api_url === "") {
            this.showContactForm = false;
        }
    }

    handleScriptError(event) {
        this.resMessage.style.opacity = 1;
        this.resMessage.innerHTML =
            "Recaptcha yüklenirken bir hata oluştu, lütfen mesaj göndermek için sayfayı yenileyin";
        this.resMessage.classList.add("color-error");
    }

    generateToken = () => {
        if (window) {
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute("6LdK8f8UAAAAAAtUSKr1kDKRmWXjgxlO19gar55B")
                    .then((token) => {
                        this.setState({
                            token: token
                        });
                    });
            });
        }
    }

    handleScriptLoad() {
        this.generateToken();
    }

    textAreaInput(event) {
        event.target.style.height = "auto";
        event.target.style.height = event.target.scrollHeight + "px";
    }

    handleSubmit(event) {
        event.preventDefault();

        if (!this.state.token) {
            this.resMessage.style.opacity = 1;
            this.resMessage.innerHTML =
                "Lütfen bir recaptcha kodu oluşturuluncaya kadar bekleyin.";
            this.resMessage.classList.add("color-error");
            return;
        }

        if (this.dataName.value.trim().length === 0) {
            this.resMessage.style.opacity = 1;
            this.resMessage.innerHTML = "İsminizi giriniz";
            this.resMessage.classList.add("color-error");
            return;
        }

        if (
            !this.dataEmail.value.match(
                /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/
            )
        ) {
            this.resMessage.style.opacity = 1;
            this.resMessage.innerHTML = "Geçerli bir eposta adresi giriniz";
            this.resMessage.classList.add("color-error");
            return;
        }

        if (this.dataMessage.value.trim().length < 15) {
            this.resMessage.style.opacity = 1;
            this.resMessage.innerHTML =
                "En az 15 karakterden oluşan bir mesaj girin.";
            this.resMessage.classList.add("color-error");
            return;
        }

        if (!this.state.submitDisabled) {
            this.setState({
                ...this.state,
                submitDisabled: true
            });

            const body = {
                name: this.dataName.value,
                email: this.dataEmail.value,
                message: this.dataMessage.value,
                "g-recaptcha-response": this.state.token
            };

            fetch(this.props.contact.api_url, {
                method: "post",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json"
                }
            })
                .then(function(res) {
                    return res.json();
                })
                .then(result => {
                    this.setState({
                        token: false,
                        submitDisabled: false
                    });
                    this.resMessage.style.opacity = 1;
                    if (!result.success) {
                        this.resMessage.innerHTML =
                            "Mesajınızı gönderirken bir hata oluştu";
                        this.resMessage.classList.add("color-error");
                    } else {
                        this.resMessage.innerHTML =
                            "Mesajınız başarıyla gönderildi";
                        this.resMessage.classList.remove("color-error");
                    }
                    this.dataName.value = "";
                    this.dataEmail.value = "";
                    this.dataMessage.value = "";
                    let _this = this;
                    setTimeout(function() {
                        _this.resMessage.style.opacity = 0;
                    }, 5000);
                })
                .catch(err => {
                    this.setState({
                        token: false,
                        submitDisabled: false
                    });
                    this.resMessage.innerHTML =
                        "Mesajınızı gönderirken bir hata oluştu";
                    this.resMessage.classList.add("color-error");
                })
                .finally(() => {
                    this.generateToken()
                });
        }
    }

    componentDidMount() {
        if (this.showContactForm) {
            let color = window
                .getComputedStyle(this.btn, null)
                .getPropertyValue("color");
            this.btn.querySelector("path").setAttribute("fill", color);
        }

        let li = this.contactArea.querySelectorAll(".item");

        li.forEach(function(e, i) {
            let p = e.querySelector("path");
            if (p)
                p.setAttribute(
                    "fill",
                    window.getComputedStyle(e, null).getPropertyValue("color")
                );
        });
    }

    render() {
        return (
            <section id="contact" className="container">
                <div>
                    <h1 className="section-title">İLETİŞİM BİLGİLERİMİZ</h1>
                </div>
                <div
                    className={"row" + (this.showContactForm ? "" : " no-form")}
                    ref={c => (this.contactArea = c)}
                >
                    {this.showContactForm && (
                        <div className="col s12 m6">
                            <form>
                                <div className="field">
                                    <label>
                                        <span className="label text-tertiary">
                                            İsminiz
                                        </span>
                                        <div className="input-border">
                                            <input
                                                type="text"
                                                ref={c => (this.dataName = c)}
                                                className="field-box"
                                                name="name"
                                                id="name"
                                                required
                                            />
                                        </div>
                                    </label>
                                </div>
                                <div className="field">
                                    <label>
                                        <span className="label text-tertiary">
                                            E-posta Adresiniz
                                        </span>
                                        <div className="input-border">
                                            <input
                                                type="email"
                                                ref={c => (this.dataEmail = c)}
                                                className="field-box"
                                                name="email"
                                                id="email"
                                                required
                                            />
                                        </div>
                                    </label>
                                </div>
                                <div className="field">
                                    <label>
                                        <span className="label text-tertiary">
                                            Mesajınız
                                        </span>
                                        <div className="input-border">
                                            <textarea
                                                style={{ overflowY: "hidden" }}
                                                ref={c =>
                                                    (this.dataMessage = c)
                                                }
                                                className="field-box"
                                                onChange={this.textAreaInput}
                                                name="message"
                                                id="message"
                                                required
                                            />
                                        </div>
                                    </label>
                                </div>
                                <div className="field">
                                    <label className="ib">
                                        <button
                                            className={
                                                "btn" +
                                                (this.state.submitDisabled
                                                    ? " disabled"
                                                    : "")
                                            }
                                            onClick={this.handleSubmit}
                                            id="submit"
                                            ref={c => (this.btn = c)}
                                        >
                                            GÖNDER{" "}
                                            <span
                                                className="icon paper-plane"
                                                style={{
                                                    display: this.state
                                                        .submitDisabled
                                                        ? "none"
                                                        : "inline-block"
                                                }}
                                            >
                                                <PaperPlane />
                                            </span>
                                            <span
                                                className="icon loading"
                                                style={{
                                                    display: !this.state
                                                        .submitDisabled
                                                        ? "none"
                                                        : "inline-block"
                                                }}
                                            >
                                                <Loading />
                                            </span>
                                        </button>
                                    </label>
                                    <div>
                                        <span className="privacy-policy">
                                            This site is protected by reCAPTCHA and the Google  <a href="https://policies.google.com/privacy">Privacy Policy</a> and  <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                                        </span> 
                                        <p
                                            className="res-message"
                                            ref={c => (this.resMessage = c)}
                                        ></p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                    <div
                        className={
                            this.showContactForm
                                ? "col s12 m6 details"
                                : "col s12 details"
                        }
                    >
                        {this.props.contact.description && (
                            <p className="text-tertiary">
                                {this.props.contact.description}
                            </p>
                        )}
                        <ul>
                            {this.props.contact.mail && (
                                <li className="text-secondary item">
                                    <span className="icon">
                                        <Envelope />
                                    </span>
                                    <a
                                        href={
                                            "mailto:" + this.props.contact.mail
                                        }
                                    >
                                        {this.props.contact.mail}
                                    </a>
                                </li>
                            )}
                            {this.props.contact.phone && (
                                <li className="text-secondary item">
                                    <span className="icon">
                                        <Mobile />
                                    </span>
                                    <a href={"tel:" + this.props.contact.phone}>
                                        {this.props.contact.phone}
                                    </a>
                                </li>
                            )}
                            {this.props.contact.address && (
                                <li
                                    className="text-tertiary item"
                                    style={{ whiteSpace: "pre" }}
                                >
                                    <span className="icon">
                                        <Mapmarker />
                                    </span>
                                    {this.props.contact.address}
                                </li>
                            )}
                            <li>
                                <MapLinks />
                            </li>
                            <li>
                                <SocialLinks />
                            </li>
                        </ul>
                    </div>
                </div>
                <Script
                    url="https://www.google.com/recaptcha/api.js?render=6LdK8f8UAAAAAAtUSKr1kDKRmWXjgxlO19gar55B"
                    onError={this.handleScriptError.bind(this)}
                    onLoad={this.handleScriptLoad.bind(this)}
                />
            </section>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                site {
                    siteMetadata {
                        contact {
                            api_url
                            description
                            mail
                            phone
                            address
                        }
                    }
                }
            }
        `}
        render={data => <Contact contact={data.site.siteMetadata.contact} />}
    />
);
